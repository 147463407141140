<template>
  <div>
    <div class="header-single header-referencia">
      <div class="container  h-100">
        <div class="row align-items-end  h-100">
          <div class="col-sm ">
            <h1 class="align-bottom">{{ $t('referenciasInstitucional.titulo') }}</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container pt-5">
      <div class="row mb-4">
        <div class="col-md-4 p-4">
          <p>{{ $t('referenciasInstitucional.paragrafo1') }} </p>
        </div>
        <div class="col-md-4 p-4">
          <p>{{ $t('referenciasInstitucional.paragrafo2') }} </p>
          <p><a href="https://institutohorus.org.br/levantamento-nacional-de-especies-exoticas-invasoras/" target="_blank">{{ $t('referenciasInstitucional.paragrafo3') }}</a>.</p>
        </div>
        <div class="col-md-4 p-0">
          <div class="p-3 rounded-5 box-citacao shadow-sm">
            <p>{{ $t('referenciasInstitucional.paragrafo4') }}</p>
            <p class="mb-0">{{ $t('referenciasInstitucional.paragrafo5_1') }}<br><br> <em>http://bd.institutohorus.org.br.<br> {{ $t('referenciasInstitucional.paragrafo5_2') }}</em>.</p>
          </div>
        </div>
      </div>
      <ReferenciasForm v-if="viewItem" :idItem="referencia.bibliography_id" :viewItem="true" :byInstitucional="true"/>
      <Referencias v-else :withPageTitle="false" :withOrderBy="true" :byInstitucional="true" @actionByInstitucional="actionByInstitucional"/>
      <div class="row mb-2 mb-xl-3">
        <div class="col-auto ml-auto text-right mt-n1">
          <button v-if="viewItem" @click="viewItem=false" class="btn bt-filtrar mt-1 mb-2">
            {{ $t('label.exibirListagem') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'driver.js/dist/driver.min.css'
import Referencias from '../../admin/referencias/Referencias.vue'
import ReferenciasForm from '../../admin/referencias/ReferenciasForm.vue'
export default {
  name: 'ReferenciasInstitucional',
  components: { Referencias, ReferenciasForm },
  data () {
    return {
      driver: null,
      viewItem: false,
      referencia: {}
    }
  },
  methods: {
    actionByInstitucional: function (item) {
      this.referencia = item
      this.viewItem = true
    }
  },
  created () {
    this.viewItem = false
  }
}
</script>
