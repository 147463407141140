export const ptBR = {
  message: {
    requiredField: 'Campo obrigatório',
    noRecords: 'Nenhum registro encontrado!',
    confirmDel: 'Tem certeza de que deseja excluir?',
    confirmApproval: 'Tem certeza de que deseja aprovar esta ocorrência?',
    noResultsFor: 'Nenhum resultado para'
  },
  login: {
    username: 'Usuário',
    password: 'Senha',
    login: 'Login',
    entrar: 'Entrar',
    descricao: 'Entre com seus dados de acesso no formulário abaixo:'
  },
  exportarFicha: {
    author: 'author',
    biodiversity_impact: 'biodiversity_impact',
    chemical_control: 'chemical_control',
    class: 'class',
    common_name: 'common_name',
    control_biological: 'control_biological',
    data_bibliography: 'data_bibliography',
    economic_impact: 'economic_impact',
    economic_use: 'economic_use',
    family: 'family',
    health_impact: 'health_impact',
    intro_data: 'intro_data',
    intro_description: 'intro_description',
    invasion_preferential_environments: 'invasion_preferential_environments',
    kingdom: 'kingdom',
    location: 'location',
    morphophysiology_description: 'morphophysiology_description',
    native_distribution_area: 'native_distribution_area',
    natural_environment: 'natural_environment',
    oorder: 'oorder',
    origin: 'origin',
    phyllum: 'phyllum',
    physical_control: 'physical_control',
    prevention: 'prevention',
    scientific_name: 'scientific_name',
    social_impact: 'social_impact',
    species_id: 'species_id',
    voc_cause_introduction: 'voc_cause_introduction',
    voc_diet: 'voc_diet',
    voc_dispersal_routes: 'voc_dispersal_routes',
    voc_dispersion_vectors: 'voc_dispersion_vectors',
    voc_form: 'voc_form',
    voc_introduction_type: 'voc_introduction_type',
    voc_reproduction: 'voc_reproduction',
    voc_spread: 'voc_spread',
    world_invasive_place: 'world_invasive_places'
  },
  label: {
    voltar: 'Voltar',
    outro: 'Outro',
    filtros: 'Filtros',
    acesseBaseDados: 'Acesse a base de dados',
    conhecaNossaRede: 'Rede Latino Americana',
    baseDadosNacional: 'Base de Dados Nacional',
    politicaDeDados: 'Política de dados',
    comiteCientifico: 'Comitê científico',
    colabore: 'Colabore',
    especiesMarinhas: 'Espécies marinhas',
    peixesAquario: 'Peixes de aquário',
    peixesAquicultura: 'Peixes de aquicultura',
    pescaDesportiva: 'Pesca desportiva',
    pets: 'Pets',
    plantasForrageiras: 'Plantas forrageiras',
    plantasOrnamentais: 'Plantas ornamentais',
    usoFlorestal: 'Uso florestal',
    tutorial: 'Tutorial',
    especies: 'Espécies',
    colaboradores: 'Colaboradorxs',
    referencias: 'Referências',
    projetos: 'Projetos',
    saibaMais: 'Saiba mais',
    contida: 'Contida',
    casual: 'Casual',
    estabelecida: 'Estabelecida',
    invasora: 'Invasora',
    exibirListagem: 'Exibir listagem',
    concluir: 'Concluir',
    fechar: 'Fechar',
    próximo: 'Próximo',
    anterior: 'Anterior',
    clickAqui: 'Clique aqui',
    numRegistrosEncontrados: 'Número de registros encontrados:',
    exibir10Reg: 'Exibir 10 registros',
    exibir25Reg: 'Exibir 25 registros',
    exibir50Reg: 'Exibir 50 registros',
    exibir100Reg: 'Exibir 100 registros',
    limpar: 'Limpar',
    buscar: 'Buscar',
    addColaborador: 'Adicionar colaborador/a',
    nome: 'Nome',
    sobrenome: 'Sobrenome',
    aprovarColaborador: 'Aprovar colaborador/a',
    salvarColaborador: 'Salvar colaborador/a',
    titulo: 'Título',
    cargo: 'Cargo',
    especialidade: 'Especialidade',
    instituicao: 'Instituição',
    endereco: 'Endereço',
    estado: 'Estado',
    cidade: 'Cidade',
    pais: 'País',
    email: 'E-mail',
    telefone: 'Telefone 1',
    telefone2: 'Telefone 2',
    websiteURL: 'Website URL',
    midiaSocial1: 'Mídia social 1',
    midiaSocial2: 'Mídia social 2',
    midiaSocial3: 'Mídia social 3',
    observacoesAdicionais: 'Observações adicionais',
    adicionar: 'Adicionar',
    editando: 'Editando',
    acoes: 'Ações',
    usuarixs: 'Usuárixs',
    adicionarUsuario: 'Adicionar usuárix',
    descricao: 'Descrição',
    ativo: 'Ativo',
    sim: 'Sim',
    nao: 'Não',
    salvarUsuarix: 'Salvar usuárix',
    senha: 'Senha',
    observacoes: 'Observações',
    grupos: 'Grupos',
    basico: 'Básico',
    administrator: 'Administrator',
    taxonomia: 'Taxonomia',
    nomeCientifico: 'Nome científico',
    adicionarEspecie: 'Adicionar espécie',
    genero: 'Gênero',
    familia: 'Família',
    ordem: 'Ordem',
    classe: 'Classe',
    phylumDivisao: 'Phylum / Divisão',
    reino: 'Reino',
    phylum: 'Phylum',
    origem: 'Origem',
    descricaoInvalida: 'Descrição inválida',
    selecioneFamilia: 'Por favor, selecione uma Família.',
    selecioneOrdem: 'Por favor, selecione uma Ordem.',
    selecioneClasse: 'Por favor, selecione uma Classe.',
    selecioneDivisao: 'Por favor, selecione um Phylum / Divisão.',
    selectioneReino: 'Por favor, selecione um Reino.',
    selectioneGenero: 'Por favor, selecione um Gênero.',
    addReferencia: 'Adicionar referência',
    autoresEX: 'Autorxs (ex. ZILLER, S. R.; DECHOUM, M. S.)',
    aprovarReferencia: 'Aprovar referência',
    salvarReferencia: 'Salvar referência',
    autorxsEX: 'Autorxs (ex. ZILLER, S. R.; DECHOUM, M. S.)',
    autores: 'Autorxs',
    local: 'Local (Ex. Salvador, BA  /  África do Sul: Cidade do Cabo)',
    localFiltro: 'Local',
    edicao: 'Edição (ex. 2 ed.)',
    editora: 'Editora',
    revista: 'Revista',
    anoPublicacao: 'Ano da publicação',
    meioDivulgacao: 'Meio de divulgação',
    volume: 'Volume (ex. v. 3)',
    numero: 'Número (ex. n. 28)',
    pagina: 'Páginas (ex. p. 25-34  /  245p.)',
    url: 'DOI / URL',
    palavraChave: 'Palavra-chave',
    anoPublic: 'Ano publicação',
    addProjeto: 'Adicionar projeto',
    aprovarProjeto: 'Aprovar Projeto',
    salvarProjeto: 'Salvar projeto',
    contato: 'Contato',
    areaGeografica: 'Área geográfica',
    objetivos: 'Objetivos',
    breveDescricao: 'Breve descrição',
    dataInicio: 'Data início',
    dataTermino: 'Data término',
    comentarios: 'Comentários',
    anoInicio: 'Ano de início',
    anoTermino: 'Ano de término',
    ocorrencias: 'Ocorrências',
    exportarDados: 'Exportar dados',
    addOcorrencia: 'Adicionar ocorrência',
    visualizarOcorrencia: 'Visualizar ocorrência',
    alterarOcorrencia: 'Alterar ocorrência',
    ocorrenciasVinculadas: 'Ocorrências vinculadas',
    areaProtegida: 'Área protegida',
    especie: 'Espécie',
    aprovarOcorrencia: 'Aprovar ocorrência',
    salvarOcorrencia: 'Salvar ocorrência',
    colaborador: 'Colaborador/a',
    municipio: 'Município',
    municipioSede: 'Município (sede)',
    municipioCentroide: 'Município (centróide)',
    referenciaLocal: 'Referência local',
    referencia: 'Referência',
    latitude: 'Latitude',
    longitude: 'Longitude',
    coordenadasCorrespondem: 'As coordenadas correspondem',
    distanciaAproximada: 'Distância aproximada',
    ambiente: 'Ambiente',
    baciaHidro: 'Bacia hidrográfica',
    descricaoInvasao: 'Descrição da invasão',
    abundancia: 'Abundância',
    situacaoPopulacional: 'Situação populacional',
    manejo: 'Manejo',
    dataEntrada: 'Data de entrada',
    dataObservacao: 'Data de observação (ex. 2020 ou 13/05/2020)',
    validado: 'Validado',
    especieMuseuHerbario: 'É um espécime de coleção zoológica ou herbário',
    selecioneTipo: 'Selecione o tipo',
    herbario: 'Herbário',
    museu: 'Coleção zoológica',
    numeroExemplaInstituicao: 'Número de exemplar (Instituição)',
    numeroExemplarColetor: 'Número de exemplar (Coletor)',
    selecioneOpcao: 'Selecione uma opção...',
    pointList1: 'A um ponto de ocorrência de um ou mais indivíduos da espécie',
    pointList2: 'Ao centro de um grupo de indivíduos da espécie',
    pointList3: 'A um ponto próximo da ocorrência, a uma distância aproximada de:',
    coordinatesSourceList1: 'A - Uma unidade política ou administrativa',
    coordinatesSourceList2: 'B - O local real da ocorrência',
    ultimoRegistroBaseDados: 'Último registros na base de dados',
    ultimosAcessos: 'Últimos acessos',
    fichaTecnicaEspecie: 'Ficha técnica das espécies',
    fichaTecnicaEspecieMMA: 'Ficha técnica das espécies MMA versão 1',
    fichaTecnicaEspecieMMA2: 'Ficha técnica das espécies MMA versão 2',
    invasoras: 'Invasora',
    contidasBrasil: 'Contida',
    ausentesBrasil: 'Ausente',
    deficiencia: 'Dados insuficientes (DD)',
    formatosDisponiveisExportacao: 'Formatos disponíveis para exportação:',
    biologiaEcologia: 'Biologia e ecologia',
    usoEconomico: 'Uso econômico',
    usoEconomicoDescricao: 'Uso econômico - descrição',
    invasaoBiologica: 'Invasão biológica',
    impactos: 'Impactos',
    habitat: 'Hábitat',
    nomeCientificoSinonimos: 'Nome científico + sinônimos',
    nomesPopulares: 'Nomes populares',
    reproducao: 'Reprodução',
    dispersao: 'Dispersão',
    dieta: 'Dieta',
    formaBiologica: 'Forma biológica',
    ambientesPreferenciaisInvasao: 'Ambientes mais suscetíveis à invasão',
    tipoIntroducao: 'Tipo de introdução',
    causaIntroducao: 'Causa de introdução',
    localIntroducao: 'Local de introdução',
    anoIntroducao: 'Ano da introdução',
    marinho: 'Marinho',
    dulcicola: 'Água doce',
    terrestre: 'Terrestre',
    validarEspecie: 'Validar espécie',
    salvarEspecie: 'Salvar espécie',
    data: 'Data',
    confiabilidade: 'Confiabilidade',
    resultadosAnalisesRisco: 'Resultados de análises de risco',
    risco: 'Risco',
    areaOrigem: 'Área de origem',
    areaDistribuicaoNatural: 'Área de distribuição natural',
    ambientesNaturais: 'Ambientes naturais',
    descricaoEspecie: 'Descrição da espécie',
    ambientesPreferenciaisInvasaoDescricao: 'Ambientes mais suscetíveis à invasão - descrição',
    outrosLocaisOndeEspecieInvasora: 'Outros locais onde a espécie é invasora',
    impactosEcologicos: 'Impactos ecológicos',
    impactosEconomicos: 'Impactos econômicos',
    impactosSociais: 'Impactos sociais',
    impactosSaude: 'Impactos à saúde',
    categoriaEICAT: 'Categoria EICAT',
    mecanismosImpactoEICAT: 'Mecanismos de impacto EICAT',
    nivelConfiancaAvaliacaoEICAT: 'Nível de confiança da avaliação EICAT',
    referenciaEICAT: 'Referência EICAT',
    dataEICAT: 'Data EICAT (ex. 2021)',
    categoriaSEICAT: 'Categoria SEICAT',
    mecanismosImpactoSEICAT: 'Mecanismos de impacto SEICAT',
    nivelConfiancaAvaliacaoSEICAT: 'Nível de confiança da avaliação SEICAT',
    referenciaSEICAT: 'Referência SEICAT',
    dataSEICAT: 'Data SEICAT (ex. 2021)',
    digitarSomenteAno: 'Digitar somente o ano - 2021',
    viasCDBCategorias: 'Vias/vetores CDB - categorias',
    viasIntroducao: 'Vias de introdução e dispersão',
    viasVetoresCDBSubcategorias: 'Vias/vetores CDB - subcategorias',
    vetoresIntroducao: 'Vetores de introdução e dispersão',
    introducao: 'Introdução',
    adicionarIntroducao: 'Adicionar introdução',
    ano: 'Ano',
    descricaoIntroducao: 'Descrição da introdução',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    excluir: 'Excluir',
    medidasPreventivas: 'Medidas preventivas',
    medidasPreventivasMMA: 'Medidas preventivas MMA',
    medidasDeteccaoPrecoce: 'Medidas de detecção precoce',
    controleMecanico: 'Controle mecânico',
    controleQuimico: 'Controle químico',
    controleBiologico: 'Controle biológico',
    distribuicaoPais: 'Distribuição no país',
    vejaMapaOcorrencias: 'Veja o mapa de ocorrências',
    ecologiaUso: 'Ecologia e uso',
    introducaoDispersao: 'Introdução e dispersão',
    analiseRisco: 'Análise de risco',
    nomeComum: 'Nome comum',
    foto: 'Foto',
    credito: 'Crédito',
    sinonimos: 'Sinônimos',
    autor: 'Autor/a',
    subespecie: 'Subespécie',
    autorSubespecie: 'Autor/a da subespécie',
    pendenteAnalise: 'Pendente de análise',
    cancelarVoltar: 'Cancelar / Voltar',
    versaoAnterior: 'Versão anterior',
    versaoAtual: 'Versão atual',
    colaboradorxs: 'Colaboradorxs',
    consultaTaxonomiaListaCompletaEspecies: 'Taxonomia - Lista completa de espécies',
    consultaEspeciesOrigemAmbientes: 'Espécies, origem, ambientes de invasão e impactos',
    consultaEspeciesManejo: 'Espécies e alternativas de manejo',
    consultaParaGerarFichaTecnica: 'Ficha técnica da espécie sem ocorrências',
    consultaEspeciesOcorrencias: 'Espécies e locais de ocorrência, com coordenadas',
    exportarDadosConsultas: 'Exportar consultas',
    ocorrenciasApp: 'Ocorrências App',
    dataCadastro: 'Data de cadastro',
    criadoPor: 'Criado por',
    nivelCerteza: 'Nível de certeza',
    byApp: 'Por aplicativo móvel',
    verDados: 'Ver dados',
    appuser: 'App user',
    profissao: 'Profissão',
    colobadorJaCadastrado: 'Colaborador já cadastrado',
    especialidadeApp: 'Especialidade App',
    cep: 'CEP',
    categoriaCDB: 'Categoria CDB',
    subCategoriaCDB: 'Subcategoria CDB',
    mensagemEnviada: 'Mensagem enviada com sucesso.',
    argentina: 'Argentina',
    brasil: 'Brasil',
    chile: 'Chile',
    costarica: 'Costa Rica',
    equador: 'Equador',
    honduras: 'Honduras',
    jamaica: 'Jamaica',
    paraguai: 'Paraguai',
    uruguai: 'Uruguai',
    exportar: 'Exportar'
  },
  home: {
    destaque1: 'Base de Dados Nacional de Espécies Exóticas Invasoras',
    destaqueDescricao1: 'Seja bem-vindx. Esta base de dados conta com a colaboração de um grande número de pessoas envolvidas com a conservação ambiental no Brasil. Navegue e conheça!',
    destaque2: 'Desde 2005 documentando espécies exóticas invasoras',
    destaqueDescricao2: 'Esta base de dados foi desenvolvida e atualizada com recursos de projetos internacionais. Atualmente conta com um Comitê Científico de apoio à gestão.',
    destaque3: 'Como funciona?',
    destaqueDescricao3: 'As consultas são de acesso livre, mas por favor cite a base de dados como referência no seu trabalho! Profissionais podem solicitar login para contribuir.',
    destaque4: 'Nosso propósito',
    destaqueDescricao4: 'Prover informação sobre espécies exóticas invasoras no Brasil para a gestão pública, programas e ações de manejo, pesquisa científica e para o público em geral.',
    titulo: 'Consulte as espécies, filtre e exporte dados',
    conteudo: 'Aqui você encontra informações sobre a área de origem das espécies exóticas invasoras, características ecológicas e biológicas, ambientes invadidos, onde são invasoras no mundo e no Brasil, incluindo um mapa de ocorrências já registradas, histórico de introdução e uso, vetores e vias de introdução e dispersão, informações sobre manejo e referências. Encontra filtros para seleção de informações e pode exportar os dados de interesse para diferentes formatos.',
    conteudoEspecies: 'Lista de espécies, características ecológicas, área de origem, onde é invasora mundo afora, vias e vetores de introdução, análise de risco, usos, opções de manejo, ocorrência no Brasil, referências.',
    conteudoColaboradores: 'Cadastro de pessoas que já enviaram dados para a base de dados. Se você precisar falar com um especialista ou saber mais sobre uma ocorrência, busque contatos aqui.',
    conteudoReferencias: 'Listagem completa de referências utilizadas como base para o preenchimento das informações sobre espécies exóticas invasoras na base de dados.',
    conteudoProjetos: 'Projetos de pesquisa e de manejo desenvolvidos para espécies exóticas invasoras no Brasil. Se você tem um projeto em andamento, escreva-nos para que possamos fazer a inclusão.',
    titulo1: 'Conheça as espécies exóticas invasoras',
    conteudo1: 'Aqui você encontra listagens de espécies exóticas invasoras organizadas por grupos que indicam o motivo ou a via pela qual foram introduzidas no Brasil. As páginas incluem uma explicação sobre cada grupo e links para fontes adicionais de informação'
  },
  areaParceiros: {
    titulo: 'Rede de Bases de Dados de Espécies Exóticas Invasoras:',
    argentina: 'Argentina',
    paraguai: 'Paraguai',
    uruguai: 'Uruguai'
  },
  redeBaseDeDados: {
    cabecalhoTitulo: 'Rede de bases de dados',
    paragrafo1: 'Esta rede de bases de dados tem origem no ano de 2005, como parte da rede temática sobre espécies exóticas invasoras I3N (IABIN Invasives Information Network), que era parte da Rede Inter-Americana de Informação sobre Biodiversidade (IABIN), estabelecida pelos governos dos países das Américas no ano de 2001.',
    paragrafo2: 'Cada país tinha um/a Líder na rede, cuja função era desenvolver uma base de dados nacional a fim de compartilhar informação sobre espécies exóticas invasoras.',
    paragrafo2_1: 'As pessoas na liderança das bases de dados nacionais são:',
    marcador1: 'Dr. Sergio M. Zalba, Professor da Universidad Nacional del Sur e pesquisador do CONICET (desde 2004);',
    marcador2: 'Dra. Sílvia R. Ziller, Fundadora e Diretora Executiva do Instituto Horus de Desenvolvimento e Conservação Ambiental (desde 2004);',
    marcador3: 'Dr. Aníbal Pauchard, Professor da Facultad de Ciencias Forestales, Universidad de Concepción;',
    marcador4: 'PhD. Eduardo Chacón-Madrigal, Professor da Universidad de Costa Rica. Pesquisador do Centro de Investigación en Biodiversidad y Ecología Tropical. Co-coordenador da Flora Invasive and Naturalized of Central America;',
    marcador5: 'Dr. Diego Inclán, Diretor Executivo do Instituto Nacional de Biodiversidad (desde 2021); e Francisco José Prieto Albuja;',
    marcador5_1: 'Dra. Lilian Ferrufino, Professora da Universidad Nacional Autónoma de Honduras;',
    marcador5_2: 'Dra. Suzanne Davis, Clearing-House Mechanism, Institute of Jamaica;',
    marcador5_3: 'Hugo Fernando del Castillo, Guyra Paraguay (desde 2006);',
    marcador5_4: 'Dr. Ernesto Brugnoli, Professor da Universidad de la República, Montevideo (desde 2005), e MSc. Marcelo Iturburu, Coordenador do Comitê Nacional de Espécies Exóticas Invasoras do Ministério do Ambiente (desde 2018).',
    paragrafo3: 'A IABIN foi financiada por um projeto do Banco Mundial (GEF) entre os anos de 2004 e 2011, então extinta. Apesar do término da iniciativa, alguns dos Líderes da rede seguem mantendo as bases de dados nacionais (Brasil, Argentina, Uruguai, Paraguai e Jamaica).',
    paragrafo4: 'No ano de 2021, recebemos um recurso da Secretaria da Convenção sobre Diversidade Biológica, como parte da iniciativa BioBridge, para revitalizar e atualizar as bases de dados da Argentina, do Brasil, do Paraguai e do Uruguai. A interface foi atualizada e novas funcionalidades foram embutidas nas bases de dados.',
    paragrafo5: 'Os desenvolvedores da Base de Dados são:',
    marcador6: 'o programador João Scucato, de Curitiba – PR, que desenvolveu a primeira versão em Microsoft Access, 2004-2005;',
    marcador7: 'o programador Alejandro Moreno, da Argentina, está envolvido com a manutenção da rede desde 2006. Manteve cópias dos arquivos e resgatou conteúdos do Paraguai e Uruguai para esta nova edição, além de desenvolver a',
    marcador7_1: 'Plataforma Comum',
    marcador7_2: 'dos países da rede.',
    marcador8: 'o Designer Rafael Moura e pelo Programador Thiago Lôbo, vinculados à',
    marcador8_1: 'Plataforma Vibbra',
    marcador8_2: 'em Florianópolis – SC, Brasil; ',
    marcador9: 'o programador Leonardo Rotondano, responsável pelo desenvolvimento do aplicativo para telefone celular usado para aporte de ocorrências de espécies exóticas invasoras.',
    paragrafo6: 'Esperamos voltar a ampliar a rede para mais países das Américas a fim de que mais dados sobre espécies exóticas invasoras fiquem disponíveis para fins de conhecimento público, gestão governamental e manejo aplicado.',
    paragrafo7: 'Apoio',
    paragrafo8: 'Período 2021',
    paragrafo9: 'Período 2004-2011'
  },
  baseDadosNacional: {
    cabecalhoTitulo: 'Base de Dados Nacional',
    titulo: 'A Base de Dados Nacional de Espécies Exóticas Invasoras',
    paragrafo1: 'Esta plataforma de informação foi desenvolvida no ano de 2004 através de uma parceria do Instituto Hórus com a Universidad Nacional del Sur em Bahía Blanca, na Argentina (Dr. Sergio Zalba), como parte da rede de informação sobre espécies exóticas invasoras, então denominada rede I3N (IABIN Invasives Information Network). A rede era, na época, coordenada por Andrea Grosse, uma Bióloga que foi muito dedicada à rede e viabilizou o desenvolvimento da estrutura da base de dados e de inúmeros produtos adicionais, como análises de risco, manuais e cursos de treinamento sobre a temática. Outra pessoa que foi fundamental para o crescimento da rede foi a Dra. Annie Simpson do USGS, então responsável pela Rede Global de Informação sobre Espécies Invasoras (GISIN), cujo objetivo era estabelecer padrões de informação para espécies exóticas invasoras a fim de facilitar a comunicação entre bases de dados globais e em diversos países. Os recursos para o desenvolvimento da base de dados foram aportados pelo Banco Mundial (GEF) para um projeto coordenado pelo Serviço Geológico dos Estados Unidos (USGS) que tinha por objetivo o estabelecimento da IABIN – Rede InterAmericana de Informação sobre Biodiversidade.',
    paragrafo2: 'A Rede I3N existiu entre os anos de 2001 e 2011, quando o projeto terminou. Nesse período, a estrutura da base de dados foi distribuída a 21 países das Américas através de cursos de treinamento para a gestão do tema em nível nacional e da própria base de dados: Argentina, Brasil, Uruguai, Chile, Paraguai, Bolívia, Peru, Equador, Colômbia, Venezuela, Suriname, República Dominicana, Bahamas, Jamaica, St. Lucia, Panamá, Costa Rica, Honduras, El Salvador, Nicarágua e Guatemala.',
    paragrafo3: 'Infelizmente, a maior parte das bases de dados nacionais se perdeu ao longo do tempo. As bases de dados que foram mantidas desde o início são a da Argentina (Universidad Nacional del Sur, Administrador Dr. Sergio Zalba); do Brasil (Instituto Hórus, Dra. Sílvia Ziller e Dra. Michele de Sá Dechoum), do Uruguai (Universidad de la República em Montevidéu, Dr. Ernesto Brugnoli e Comitê de Espécies Exóticas Invasoras Ministério do Meio Ambiente, M.Sc. Marcelo Iturburu) e da Jamaica (Institute of Jamaica, Dra. Suzanne Davis). A cooperação entre essas bases de dados nunca foi interrompida pelxs administradorxs.',
    paragrafo4: 'No ano de 2011 a base de dados foi atualizada, passando do formato original em Microsoft Access para o formato de software livre, em MySQL. Nessa ocasião foram feitas inúmeras correções e melhorias a partir de relatos de experiência dos usuários em diversos países. A nova versão foi então distribuída aos países que faziam parte da rede.',
    paragrafo5: 'Dez anos depois, em 2021, a Iniciativa BioBridge da Secretaria da Convenção Internacional sobre Diversidade Biológica em Montreal, Canadá, proveu recursos para uma nova atualização para a Argentina, o Brasil, o Uruguai e o Paraguai. Novamente foram realizadas diversas melhorias visando facilitar o aporte, a edição e a exportação de dados pelxs usuárixs. Esperamos que esta versão seja mais acessível e mais amigável, e que a Base de Dados Nacional possa prover base para a gestão pública, para manejo em campo e leve à elaboração de muitos artigos técnicos e científicos. Também esperamos que o conhecimento sobre espécies exóticas invasoras estimule o uso de espécies nativas, especialmente, a fim de salvaguardar a diversidade biológica e a resiliência dos ecossistemas naturais do Brasil e das Américas.',
    paragrafo6: 'Agradecemos às pessoas que têm colaborado com a Base de Dados Nacional, tanto pelo aporte de dados e ajuda na validação de espécies e ocorrências, como por todo tipo de suporte que é requerido. A riqueza de dados disponível é resultado, sem dúvida, de um significativo esforço conjunto. Agradecemos especialmente à Dra. Michele de Sá Dechoum, Docente da UFSC e Coordenadora do Laboratório de Ecologia de Invasões Biológicas, Manejo e Conservação (',
    paragrafo7: ') pela parceria na gestão da Base de Dados Nacional e envolvimento de estudantes na atualização de informações através de um projeto de extensão universitária.'
  },
  politicaDeDados: {
    cabecalhoTitulo: 'Política de dados',
    titulo: 'Conceitos e fontes de referência',
    criterio: 'Critérios para inclusão de espécies',
    avaliacao: 'Avaliação e revisão de dados',
    validacao: 'Validação de dados',
    paragrafo1: 'Para fins desta base de dados define-se, em acordo com a Convenção Internacional sobre Diversidade Biológica:',
    marcador1: 'espécie nativa – aquela que se encontra na área de distribuição geográfica onde evoluiu e forma parte de uma comunidade biótica em equilíbrio;',
    marcador2: 'espécie exótica – aquela que se encontra fora de sua área de distribuição natural, passada ou presente;',
    marcador3: 'espécie exótica invasora – aquela espécie exótica que ameaça hábitats, ecossistemas ou outras espécies, causando impactos e alterações em ambientes naturais.',
    paragrafo2: 'A base de dados nacional de espécies exóticas invasoras do Brasil inclui apenas espécies exóticas invasoras que já se encontram presentes no país. As espécies registradas encontram-se sempre relacionadas a pelo menos uma ocorrência geográfica no Brasil.',
    paragrafo3: 'As espécies que constam na base de dados não devem ser consideradas como invasoras de forma homogênea em todo o país, pois sempre há um ou mais ambientes preferenciais para invasão onde cada espécie expressa maior agressividade. Por isso cada espécie estão sempre indicados os ambientes e também os locais de ocorrência. Não são incluídas na base de dados espécies sem ao menos uma ocorrência confiável conhecida.',
    paragrafo4: 'O sistema de classificação de ambientes terrestres utilizado é o Manual de Classificação da Vegetação Brasileira do IBGE, edição de 2012. Os termos utilizados para ambientes marinhos e de água doce foram definidos com especialistas nas respectivas áreas. O vocabulário controlado para os campos em comum entre as bases de dados foi adotado da Base de Dados Global de Espécies Invasoras do Grupo Especialista em Espécies Invasoras vinculado à IUCN',
    paragrafo5_1: 'As referências taxonômicas principais são o Missouri Botanical Garden ',
    paragrafo5_2: 'The Plant List ',
    paragrafo5_3: 'e o Integrated Taxonomic Information System dos Estados Unidos - ITIS. ',
    paragrafo5_4: 'Outras bases de dados são comumente consultadas para complementação de informações ou questões taxonômicas de grupos específicos, como World Register of Marine Species',
    paragrafo5_5: 'além da Base de Dados Global de Espécies Invasoras',
    paragrafo5_6: 'e do Compêndio de Espécies Invasoras da CABI.',
    paragrafo6: 'Em cada ponto de ocorrência, cada espécie é enquadrada em uma das seguintes categorias:',
    marcador4: '– quando sua presença está restrita a estruturas de uso antrópico, como laboratórios ou áreas de cultivo que não permitam o escape de indivíduos para ambientes naturais;',
    marcador5: '– quando a espécie se encontra no ambiente natural, em geral plantada ou cultivada, ou recém-introduzida, ainda sem evidências de reprodução ou dispersão; ',
    marcador6: '– quando a espécie se encontra no ambiente natural já com uma população viável, reproduzindo-se, porém apenas localmente, ainda sem dispersão ampla para outras áreas;',
    marcador7: '– quando a espécie se encontra no ambiente natural, já em reprodução e em processo de expansão, seja inicial ou avançado, para outras áreas além do ponto onde foi introduzida.',
    paragrafo7: 'Assim sendo, é importante que fique claro que uma espécie pode ser invasora num local, ainda estar em fase de latência (adaptação) em outro, e não ser invasora em ainda outro ambiente.',
    paragrafo8: 'Cada informação de ocorrência recebida fica sempre vinculada à fonte, ou seja, à pessoa que a forneceu e/ou a um trabalho científico, incluindo um ou  mais autores. Outros dados são referenciados nos campos descritivos à medida do possível. Se houver dúvidas, questionamentos ou necessidades de mais informação sobre algum dado, a fonte pode ser indicada ou consultada em busca de esclarecimentos. Esse vínculo tem principalmente o intuito de preservar a fonte da informação, reconhecer a autoria e valorizar o trabalho e o apoio dxs colaboradorxs.',
    paragrafo8_en: '',
    paragrafo9_1: 'Se você tiver dados sobre espécies exóticas invasoras para contribuir com a base de dados, por favor utilize a planilha disponível no site e envie para ',
    paragrafo9_2: ' ou entre em contato conosco.',
    paragrafo10: 'Se tiver dúvidas ou questionamentos, por favor escreva para ',
    paragrafo11: 'Os critérios básicos para a inclusão de espécies na base de dados são: (a) que estejam presentes no Brasil e haja pelo menos um ou alguns registros de ocorrência confiáveis; (b) tenham histórico de invasão registrado no próprio país ou em outra parte do mundo, geralmente em condições climáticas que propiciam a adaptação da espécie a algum tipo climático brasileiro. Pode haver exceções para espécies exóticas invasoras que ainda não estejam expressando comportamento invasor, mas sejam consideradas de alto risco para a diversidade biológica brasileira e ocorram no país de forma contida (em cativeiro, aquários, laboratórios, etc.) ou em países limítrofes ou com os quais o Brasil mantém relações comerciais importantes, com risco iminente de chegada. Nenhuma espécie é incluída sem que haja pelo menos um ou mais registros validados de ocorrência.',
    paragrafo12: 'A base de dados contempla espécies exóticas invasoras de todos os grupos biológicos que tenham potencial de impacto sobre a diversidade biológica e/ou sobre ambientes naturais. Muitas vezes as mesmas espécies também geram impacto sobre a economia, sobre valores sociais ou culturais ou sobre a saúde humana ou animal, porém o foco desta base de dados são as espécies que têm impacto sobre sistemas naturais e sobre a diversidade biológica.',
    paragrafo13: 'Uma vez que uma espécie é registrada na base de dados, os dados complementares são incluídos. À medida que há mais informação disponível, são incorporadas novas informações e, principalmente, novas ocorrências da espécie no país. Esses dados são obtidos de publicações técnicas e científicas, de colaboradores diretos que realizam trabalho de campo, de gestores e funcionários de unidades de conservação ou por observação e coleta de pontos diretamente no campo. A inclusão de coordenadas geográficas é obrigatória para todos os registros.',
    paragrafo14: 'Os dados são revisados continuamente à medida que mais informações sobre uma espécie é publicada ou comunicada diretamente por especialistas. Pode haver questionamento sobre espécies inseridas na base de dados em função de novas informações, caso em que é buscado apoio de especialistas no grupo biológico em questão para decidir se a espécie deve permanecer ou ser removida. Algumas espécies incluídas nos primeiros anos de trabalho da base de dados foram posteriormente removidas porque não havia dados suficientes para atender os critérios de inclusão ou faltavam evidências de invasão biológica. Isso pode ocorrer, por exemplo, quando se reconhece uma espécie exótica que é observada em algum local, porém não há evidências de invasão ao longo do tempo, nem há dados que confirmem o potencial de invasão no Brasil ou em outras partes do mundo. Nesses casos, os dados das espécies removidas são armazenados à parte e ficam numa lista de espera, pois se essa condição mudar, os dados podem ser facilmente restaurados.',
    paragrafo15: 'A validação dos dados inseridos na base de dados por colaboradores é feita com base na confiabilidade das informações, especialmente com relação à identificação da espécie e ao local de ocorrência.',
    paragrafo16: 'Validação taxonômica',
    marcador8: 'A pessoa que aportou os dados é especialista no grupo biológico em questão ou tem experiência/conhecimento equivalente?',
    marcador9: 'A ocorrência está documentada mediante uma fotografía que permite a identificação confiável do organismo em questão?',
    marcador10: 'A ocorrência está documentada em uma publicação indexada e com avaliação de pares ou num documento técnico elaborado ou revisado por especialistas?',
    marcador11: 'A informação corresponde a um exemplar de herbário ou a espécime depositado numa coleção de museu?',
    marcador12: 'A ocorrência corresponde a uma espécie que já estava registrada para o país?',
    marcador13: 'O grupo a que pertence a espécie tem taxonomia estável e bem conhecida?',
    marcador14: 'As características da espécie permitem a identificação com facilidade?',
    paragrafo17: 'A resposta positiva a pelo menos uma das perguntas 1, 2, 3 ou 4 recomenda a validação do dado. Se essas quatro perguntas tiverem respostas negativas, o dado somente será validado se as três perguntas subsequentes tiverem respostas positivas (5, 6 e 7).',
    paragrafo18: 'Validação geográfica',
    marcador15: 'O dado inclui coordenadas geográficas? São consideradas coordenadas tomadas diretamente no local da observação, como outras que correspondem à referência geográfica próxima (caso indicada).',
    marcador16: 'O dado inclui uma descrição do local que permite sua identificação confiável?',
    marcador17: 'A ocorrência está documentada mediante uma fotografía que permite a identificação confiável do local?',
    marcador18: 'As coordenadas geográficas coincidem com a localização da área descrita e/ou fotografiada?',
    marcador19: 'A espécie já está citada para o país e, nesse caso, o local está incluído na área de distribuição conhecida da espécie ou num local compatível com os ambientes onde a espécie já ocorre (ambientes preferenciais de invasão conhecidos)?',
    marcador20: 'As condições ecológicas do local, inferidas em função da localização indicada, são compatíveis com os requerimentos gerais de nicho da espécie?',
    paragrafo19: 'O dado será validado do ponto de vista da localização geográfica se:',
    marcador21: 'a resposta a uma das perguntas 1, 2 e/ou 3, e 4 é positiva; ',
    marcador22: 'a resposta à pergunta 1 é positiva ou negativa, uma resposta negativa às perguntas 2 e 3, mas as respostas às perguntas 5 e 6 são positivas. ',
    paragrafo20: 'O dado de localização não será validado se: ',
    marcador23: 'a resposta à pergunta 1 e às perguntas 2 e/ou 3 são positivas, mas a resposta à pergunta 4 é negativa;  ',
    marcador24: 'a resposta à pergunta 1 é positiva ou negativa e a resposta às perguntas 5 e 6 é positiva somente para uma ou para nenhuma das perguntas 5 e 6.',
    paragrafo21: 'Finalmente, o dado completo é validado quando aprovado tanto na análise de identificação como na de localização.'
  },
  comiteCientifico: {
    cabecalhoTitulo: 'Comitê científico',
    paragrafo1: 'A Base de Dados Nacional de Espécies Exóticas Invasoras conta com o apoio de um grupo de especialistas em diversas áreas do conhecimento para realizar a validação de dados recebidos. Nesse grupo estão taxonomistas ou especialistas em grupos específicos de espécies, assim como profissionais com experiência de campo cujo conhecimento é de alta relevância para apoiar a verificação de informações.',
    paragrafo2: 'A partir de 2021 essa contribuição passou a ser formalizada, sendo algumas pessoas convidadas a compor um Comitê Científico para apoio à validação de dados e à gestão da Base de Dados Nacional de Espécies Exóticas Invasoras. O papel do Comitê Científico é prover apoio para a validação de dados, aportar dados de interesse para a base de dados e ajudar a construir e manter a rede de colaboradores para viabilizar a atualização das informações e aumentar o número de aportes de dados.',
    paragrafo3: 'O Comitê Científico é nomeado para períodos de três anos, podendo ser renovadas as nomeações ou substituídos os membros. O primeiro Comitê Científico foi formado ao longo do ano de 2021, por:',
    marcador1: 'Dra. Sílvia R. Ziller, Eng. Florestal, Fundadora e Diretora Executiva do Instituto Hórus, Administradora da Base de Dados Nacional de Espécies Exóticas Invasoras do Brasil;',
    marcador2: 'Dra. Michele de Sá Dechoum, Bióloga, Docente do Departamento de Ecologia e Zoologia da Universidade Federal de Santa Catarina e colaboradora do Instituto Hórus desde 2007 e Administradora da Base de Dados;',
    marcador3: 'Dr. Rafael Dudeque Zenni, Eng. Florestal, Docente do Departamento de Ecologia e Conservação, Instituto de Ciências Naturais da Universidade Federal de Lavras – MG, colaborador da base de dados desde 2004;',
    marcador4: 'Dr. Sergio Zalba, Biólogo, Docente da Universidad Nacional del Sur em Bahía Blanca e administrador da Base de Dados Nacional de Espécies Exóticas Invasoras da Argentina;',
    marcador4b: 'Dra. Rosana M. Rocha, Docente do Departamento de Zoologia da Universidade Federal do Paraná;',
    marcador4c: 'Dr. Mário Luís Orsi, Departamento de Biologia Animal e Vegetal, LEPIB, Universidade Estadual de Londrina - PR.',
    paragrafo4: 'Atribuições do Comitê Científico',
    marcador5: 'decisão sobre a inclusão de espécies novas na base de dados; ',
    marcador6: 'decisão sobre a eliminação de espécies por falta ou inconsistência de dados; ',
    marcador7: 'apoio à validação de informações da base de dados;',
    marcador8: 'apoio na revisão e busca de dados; ',
    marcador9: 'apoio à busca e consulta a colaboradores e especialistas para aporte e validação de dados;',
    marcador10: 'recomendações de melhorias na base de dados;',
    marcador11: 'melhoria contínua do nível de informação.'
  },
  especiesInstitucional: {
    titulo: 'Espécies',
    paragrafo1: 'Esta é a parte da base de dados que contém informações sobre espécies exóticas invasoras. Esses dados vêm sendo coletados desde o ano de 2004, quando foi realizado o primeiro levantamento nacional pelo Ministério do Meio Ambiente. As informações são enviadas por colaboradores diversos, incluindo especialistas em distintos grupos biológicos que também ajudam a validar dados, e compiladas a partir de artigos científicos e publicações técnicas.',
    paragrafo2_1: 'Se você tiver dados de ocorrência dessas espécies ou sobre espécies exóticas invasoras ainda não contempladas, ',
    paragrafo2_2: 'contribua para atualizar esta base de dados',
    verTutorial: 'Abrir tutorial',
    paragrafo3: 'Para citar dados do sistema, utilize o seguinte formato:',
    paragrafo4_1: 'Base de Dados Nacional de Espécies Exóticas Invasoras. Instituto Hórus de Desenvolvimento e Conservação Ambiental, Florianópolis – SC.',
    paragrafo4_2: ' Acessado em __/__/____ (data)',
    tutorialTitulo1: 'Como citar a base de dados',
    tutorialDescricao1: 'Veja aqui o formato da referência para citação da base de dados em publicações.',
    tutorialTitulo2: 'Use os filtros',
    tutorialDescricao2: 'Aqui temos algumas opções para filtrar conteúdo.',
    tutorialTitulo3: 'Use os filtros: selecione dados',
    tutorialDescricao3: 'Escolha filtros e faça combinações para selecionar dados por local, forma biológica, uso, grupo taxonômico, etc.',
    tutorialTitulo4: 'Número de registros',
    tutorialDescricao4: 'Este é o número de espécies exóticas invasoras na base de dados ou resultante das filtragens realizadas.',
    tutorialDescricao5: 'Aqui você pode selecionar quantos registros visualizar por página.',
    tutorialTitulo6: 'Lista de espécies exóticas invasoras',
    tutorialDescricao6: 'Listagem de todas as espécies ou resultados do uso de filtros. Clique no nome da espécie para ver os dados completos.',
    tutorialTitulo6_1: 'Exportar dados',
    tutorialDescricao6_1: 'Aqui você pode filtrar dados e escolher o formato para exportar.',
    tutorialTitulo7: 'Taxonomia',
    tutorialDescricao7: 'Classificação taxonômica da espécie selecionada, nomes populares, sinônimos e uma foto quando possível!',
    tutorialTitulo8: 'Ecologia e uso',
    tutorialDescricao8: 'Descrição, biologia e ecologia da espécie selecionada, de onde vem, que ambientes invade e usos econômicos.',
    tutorialTitulo9: 'Introdução e dispersão',
    tutorialDescricao9: 'Como a espécie chegou onde é invasora, por quê foi introduzida e como se dispersa para outros lugares.',
    tutorialTitulo10: 'Impactos',
    tutorialDescricao10: 'Ambientais, econômicos, à saúde e culturais. Classificação de impactos ambientais pelo protocolo EICAT – IUCN.',
    tutorialTitulo11: 'Manejo',
    tutorialDescricao11: 'Medidas preventivas, de detecção precoce, controle mecânico, químico e biológico para a espécie selecionada.',
    tutorialTitulo12: 'Análise de Risco',
    tutorialDescricao12: 'Resultados de análises de risco para invasão biológica realizadas para a espécie selecionada.',
    tutorialTitulo13: 'Ocorrências',
    tutorialDescricao13: 'Locais, ambientes, unidades de conservação, estados onde a espécie ocorre no Brasil e estágio de invasão – veja o mapa!',
    tutorialDescricao14: 'Lista de referências usadas para compilar dados sobre a espécie selecionada e outras publicações que citam a espécie.',
    tutorialTitulo15: 'Projetos',
    tutorialDescricao15: 'Lista de projetos de pesquisa e manejo que contemplam a espécie selecionada.'
  },
  colaboradoresInstitucional: {
    titulo: 'Colaboradorxs',
    paragrafo1: 'São colaboradorxs da Base de Dados Nacional as pessoas que enviam informações sobre espécies exóticas invasoras, seja na forma de publicações técnicas ou científicas, como dados associados a espécimes de herbário ou museu, ou de observações de campo, assim como ajudam a identificar espécies. ',
    paragrafo2: 'A contribuição de informações de ocorrência das espécies já listadas é extremamente relevante para manter registros de distribuição atualizados e detectar novos locais de invasão, contribuindo para iniciativas de gestão e o manejo, assim como para a divulgação do conhecimento científico. ',
    paragrafo3: 'Para citar dados do sistema, utilize o seguinte formato:',
    paragrafo4_1: 'Base de Dados Nacional de Espécies Exóticas Invasoras. Instituto Hórus de Desenvolvimento e Conservação Ambiental, Florianópolis – SC.',
    paragrafo4_2: ' Acessado em __/__/____ (data)'
  },
  especiesMarinhasInstitucional: {
    titulo: 'Espécies marinhas',
    paragrafo1: 'A maioria das espécies exóticas invasoras marinhas são introduzidas involuntariamente através da navegação comercial e movimento de estruturas como plataformas de óleo e gás. Algumas espécies são transportadas na forma de larvas em água de lastro de navios, outras aderem a cascos de navios e outras estruturas, processo que se chama bioincrustação. A maior parte das espécies invasoras marinhas são invertebrados (camarões, caranguejos, cracas, moluscos, poliquetas, etc.), algumas são peixes, outras são algas. ',
    paragrafo2: 'Sendo que a área de origem de muitas espécies não é bem conhecida, às vezes é difícil classificar as espécies como invasoras. Assim, por vezes se faz inferências sobre processos de invasão biológica com base no conhecimento de que as espécies não existiam num dado local ou região. ',
    paragrafo3: 'Saiba mais.',
    paragrafo4: 'Tubastrea coccinea - coral-sol',
    paragrafo4b: 'Foto: Bruna Folchini Gregoletto',
    paragrafo5: 'Pterois volitans - peixe-leão',
    paragrafo5b: 'Foto: Instituto Hórus',
    paragrafo6: 'Ophiotela mirabilis - ofiúro',
    paragrafo7: 'Charybdis helleri - siri-bidu'
  },
  peixesAquarioInstitucional: {
    titulo: 'Peixes de aquário',
    paragrafo1: 'Os peixes de aquário são os “animais de estimação”, ou “pets”, mais numerosos do mundo atualmente. O Brasil é um grande fornecedor de peixes ornamentais devido à riqueza  da bacia amazônica e do Pantanal. Ainda assim, a maior parte dos peixes comercializados é exótico aos locais de venda. Esses animais jamais devem ser liberados em lagos, rios, ou no mar porque podem predar espécies nativas, alterar o ambiente aquático ou transmitir doenças e parasitos.',
    paragrafo2: 'Além disso, podem se tornar invasores, como é o caso do peixe-leão, que escapou de um aquário na Flórida, EUA, e agora está em todo o mar do Caribe e há risco de chegar à costa brasileira.',
    paragrafo3: 'É um risco grande para recifes de coral, como em Abrolhos e Fernando de Noronha. Jamais libere animais de estimação na natureza!',
    paragrafo4: 'Saiba mais',
    marcador1: 'Peixes ornamentais exóticos ameaçam a biodiversidade',
    marcador2: 'Exóticos invasores',
    marcador3: 'Ciência Hoje',
    paragrafo5: 'Laetacara araguaiae - carazinho',
    paragrafo5b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo6: 'Pterygoplichthys ambrosettii (juvenil)',
    paragrafo6b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo7: 'Laetacara araguaiae carazinho',
    paragrafo7b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo8: 'Hyphessobrycon eques - mato-grosso',
    paragrafo8b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo9: 'Astronotus crassipinnis - oscar, apaiari',
    paragrafo9b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo10: 'Danio rerio - paulistinha',
    paragrafo10b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo11: 'Pterois volitans - peixe-leão',
    paragrafo11b: 'Foto: Instituto Hórus'
  },
  peixesAquiculturaInstitucional: {
    titulo: 'Peixes de aquicultura',
    paragrafo1: 'A aquicultura é a principal causa de introdução de peixes de água doce no Brasil. Muitas espécies são bastante agressivas e têm histórico de extinguir espécies nativas de peixes em diversos países do mundo, como a tilápia-do-nilo e o bagre-africano. O panga é um peixe da Ásia que está começando a ser criado no Brasil sem autorização do IBAMA, com alto potencial de invasão e impacto na fauna nativa. Procure se informar antes de decidir que peixes vai consumir, de modo a não contribuir para os impactos que podem causar. ',
    paragrafo2: 'O problema é que as estruturas de criação – açudes e tanques – não são seguras e os peixes escapam para o ambiente natural, ou são até mesmo soltos intencionalmente durante o manejo dos tanques. Podem predar espécies nativas, alterar o ambiente aquático ou transmitir doenças e parasitos.',
    paragrafo3: 'Conheça o código de conduta da FAO para a aquicultura responsáve.',
    paragrafo3b: 'http://www.fao.org/fishery/code/en',
    paragrafo4: 'Cichla kelberi Tucunaré',
    paragrafo5: 'Coptodon rendalli Tilápia',
    paragrafo6: 'Cyprinus carpio Carpa-comum',
    paragrafo7: 'Micropterus salmoides - Black bass',
    paragrafo7b: 'Foto: LEPIB - UEL PR',
    paragrafo8: 'Oreochromis niloticus Tilápia-do-nilo'
  },
  pescaDesportivaInstitucional: {
    titulo: 'Pesca desportiva',
    paragrafo1: 'A pesca desportiva é uma atividade de lazer que, com frequência, envolve a introdução de espécies exóticas geralmente predadoras vorazes. Embora a introdução de espécies e a transposição de peixes para bacias hidrográficas onde não ocorrem seja proibida sem autorização do IBAMA (Portaria IBAMA 145-N/1998), muitos exemplares de peixes têm sido retirados de suas bacias hidrográficas de origem e levados a outras onde não são nativos. Esse processo gera impactos a peixes nativos por predação, competição e transmissão de parasitas e doenças, além de desequilíbrios ambientais severos.',
    paragrafo2: 'Associada à pesca desportiva está a introdução de espécies exóticas usadas como iscas vivas, que podem escapar do anzol ou mesmo ser jogadas nos rios ao final da atividade de lazer. Peixes e outras espécies usadas como isca viva de- vem ser usadas apenas se forem nativas do rio onde se realiza a atividade de pesca para evitar a introdução de espécies exóticas e impactos a espécies nativas.',
    paragrafo3: 'Jamais libere animais exóticos na natureza!',
    paragrafo4: 'Micropterus salmoides - black bass',
    paragrafo4b: 'Foto: LEPIB - UEL PR',
    paragrafo5: 'Cichla kelberi - tucunaré',
    paragrafo5b: 'Foto: LEPIB - UEL PR',
    paragrafo6: 'Cichla kelberi - tucunaré',
    paragrafo6b: 'Foto: LEPIB - UEL PR',
    paragrafo7: 'Saiba mais'
  },
  petsInstitucional: {
    titulo: 'Pets',
    paragrafo1_1: 'O termo ',
    pet: 'pet',
    paragrafo1_2: ' se refere a animais de companhia, desde peixes de aquário até cães e gatos. Alguns animais incluídos na lista, especialmente cães e gatos, somente são considerados problemáticos quando encontrados no interior de áreas naturais onde predam a fauna nativa e causam impactos severos à conservação da biodiversidade.',
    paragrafo2: 'Não deixe seu animal de estimação ficar solto em áreas naturais!',
    paragrafo3: 'Trachemys scripta - tigre-dágua americano',
    paragrafo3b: 'Foto: Marcos Tortato',
    paragrafo4: 'Felis catus - gato doméstico',
    paragrafo4b: 'Foto: Instituto Hórus',
    paragrafo5: 'Canis familiaris - cachorro doméstico',
    paragrafo5b: 'Foto: Marcos Tortato',
    paragrafo6: 'Trachemys dorbignyi - tigre-d´água',
    paragrafo6b: 'Foto: Marcos Tortato'
  },
  plantasForrageirasInstitucional: {
    titulo: 'Plantas forrageiras',
    paragrafo1: 'Muitas plantas foram introduzidas para uso como forrageiras, ou seja, para alimentar animais de criação, como bois, vacas, cabras, cavalos e burros. São principalmente gramíneas, dentre as quais as braquiárias são invasoras muito agressivas em áreas naturais e áreas degradadas, até mesmo em áreas úmidas e pequenos córregos. Mas também há arvoretas como a leucena e árvores como a algaroba, cujas vagens são utilizadas para alimentação de cabras. ',
    paragrafo2: 'Essas espécies foram disseminadas amplamente no mundo tropical e causam impactos importantes ao ambiente por modificar ciclos naturais de fogo, reduzir o volume de água e deslocar espécies nativas de plantas, formando agrupamentos densos e dominantes.',
    paragrafo3: 'Saiba mais nessa publicação',
    paragrafo4: 'Urochloa decumbens - braquiária',
    paragrafo4b: 'Foto: Instituto Hórus',
    paragrafo5: 'Prosopis juliflora - algaroba',
    paragrafo5b: 'Foto: Leonaldo Andrade',
    paragrafo6: 'Melinis minutiflora - capim-gordura',
    paragrafo6b: 'Foto: Carlos Romero Martins',
    paragrafo7: 'Hyparrhenia rufa - capim-jaraguá',
    paragrafo7b: 'Foto: Instituto Hórus'
  },
  plantasOrnamentaisInstitucional: {
    titulo: 'Plantas ornamentais',
    paragrafo1: 'Plantas ornamentais perfazem cerca de 70% de todas as plantas exóticas invasoras presentes no Brasil. A escolha de plantas para jardins, sombra e arborização urbana pode contribuir para a conservação da natureza, que inclui a provisão de água, a estabilidade climática e muitos outros “serviços” prestados pela natureza ao ser humano.',
    paragrafo2_1: 'Cultive plantas nativas da sua região',
    paragrafo2_2: 'Conheça, evite e substitua plantas exóticas invasoras no seu jardim.',
    paragrafo3: 'Consulte plantas alternativas para a sua região no ',
    arquiflora: 'Arquiflora',
    paragrafo4: 'Tecoma stans - ipê-de-jardim',
    paragrafo4b: 'Foto: Instituto Hórus',
    paragrafo5: 'Furcraea foetida - piteira',
    paragrafo5b: 'Foto: Instituto Hórus',
    paragrafo6: 'Dieffenbachia picta - comigo-ninguém-pode',
    paragrafo6b: 'Foto: Maria Clara Forsberg',
    paragrafo7: 'Asparagus densiflorus - aspargo',
    paragrafo7b: 'Foto: Marcelo Vitorino',
    paragrafo8: 'Terminalia catappa - castanheira, amendoeira',
    paragrafo8b: 'Foto: Instituto Hórus'
  },
  projetosInstitucional: {
    titulo: 'Projetos',
    paragrafo1: 'Nesta seção você encontra referências sobre projetos de pesquisa, manejo e temas relacionados a espécies exóticas invasoras já realizados ou em andamento. O objetivo é facilitar a integração entre pessoas que têm interesse nas mesmas espécies para a troca de experiências, assim como prover informação sobre o que está sendo feito numa dada região. ',
    paragrafo2_1: 'Se você participa ou já participou de algum projeto sobre espécies exóticas invasoras, contribua com a base de dados ',
    paragrafo2_2: ', enviando informação sobre o projeto ',
    paragrafo2_3: 'para que possamos cadastrá-lo. Esperamos que a divulgação beneficie o seu trabalho.',
    paragrafo3: 'Para citar dados do sistema, utilize o seguinte formato:',
    paragrafo4_1: 'Base de Dados Nacional de Espécies Exóticas Invasoras. Instituto Hórus de Desenvolvimento e Conservação Ambiental, Florianópolis – SC.',
    paragrafo4_2: 'Acessado em __/__/____ (data)'
  },
  referenciasInstitucional: {
    titulo: 'Referências',
    paragrafo1: 'As referências listadas aqui foram utilizadas para o preenchimento das informações sobre espécies exóticas invasoras na base de dados, seja sobre dados da biologia ou ecologia das espécies, sobre o manejo ou localidades de ocorrência. Nesta seção você tem acesso a todas as referências já utilizadas, enquanto que na seção sobre Espécies vê apenas a lista de referências utilizadas para a espécie selecionada.',
    paragrafo2: 'Se você tem publicações sobre espécies exóticas invasoras que não estão contempladas aqui, contribua para a atualização da Base de Dados Nacional através do envio dessas referências.',
    paragrafo3: 'Contribua com dados no site',
    paragrafo4: 'Para citar dados do sistema, utilize o seguinte formato:',
    paragrafo5_1: 'Base de Dados Nacional de Espécies Exóticas Invasoras. Instituto Hórus de Desenvolvimento e Conservação Ambiental, Florianópolis – SC.',
    paragrafo5_2: 'Acessado em __/__/____ (data)'
  },
  usoFlorestalInstitucional: {
    titulo: 'Uso florestal',
    paragrafo1: 'Diversas espécies de árvores exóticas foram introduzidas no Brasil a partir da década de 1950 por iniciativas de governo para desenvolver a indústria florestal de produção de papel, celulose, madeira e outros subprodutos. Algumas dessas espécies se tornaram invasoras, sendo os pínus especialmente distribuídos em inúmeros ambientes. Essas espécies requerem um manejo que inclua o controle contínuo e sua contenção em talhões florestais para evitar impactos ambientais e à paisagem. A certificação florestal exige o controle de espécies exóticas invasoras e é preciso eliminar essas árvores de áreas naturais, margens de rodovias e ferrovias, florestas ciliares e outros ambientes onde não são cultivadas.',
    paragrafo2: 'O gênero pínus é tido como o gênero de plantas com maior amplitude de invasão no hemisfério sul! Os pínus deslocam espécies nativas em ecossistemas abertos e são grande consumidores de água, mais do que espécies nativas.',
    paragrafo3: 'Saiba mais neste artigo',
    paragrafo4: 'Pínus sp. - pinheiro-americano',
    paragrafo4b: 'Foto: Instituto Hórus',
    paragrafo5: 'Acacia mearnsii - acácia-negra',
    paragrafo5b: 'Foto: Instituto Hórus',
    paragrafo6: 'Acacia mangium - acácia',
    paragrafo6b: 'Foto: Instituto Hórus'
  },
  contatoInstitucional: {
    cabecalhoTitulo: 'Contato',
    contato: 'Contato',
    p1: 'Preencha todos os campos do formulário abaixo:',
    nome: 'Nome',
    email: 'E-mail',
    instituicao: 'Instituição',
    mensagem: 'Mensagem',
    enviar: 'Enviar'
  }
}
